import React from 'react';

const GradientButton = ({text, gradientFrom, gradientTo, textColor}) => {
    return (
        <div className="w-full h-full flex justify-center items-center absolute left-0 top-0">
            <div
                style={{
                    backgroundSize: '200% auto',
                    backgroundImage: `linear-gradient(to right, var(--${gradientFrom}) 0%, var(--${gradientTo}) 50%, var(--${gradientFrom}) 100%)`,
                    animation: 'gradient 6s linear infinite',
                }}
                className={`w-full h-full flex rounded-lg ${textColor}  shadow-lg hover:shadow-xl justify-center items-center`}
            >
                {text}
                <style>{`
                    @keyframes gradient {
                        0% { background-position: 0% center; }
                        100% { background-position: -200% center; }
                    }
                `}</style>
            </div>
        </div>
    );
};

export default GradientButton;
